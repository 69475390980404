import {
  createStylesParam,
  createStylesParams,
  StyleParamType,
  type IStyleParam,
} from '@wix/tpa-settings';
import { type CustomCssVarsFn } from '@wix/yoshi-flow-editor';
import {
  AUTHOR_INFO_TYPE_NAME_PICTURE,
  Layout,
  PostListWidgetPaginationType,
} from '@wix/communities-blog-client-common';
import { type GetIStylesParams, type StyleParamsValues } from '@app/common/style-params';

/** @deprecated Legacy, if 1 - blog-isAuthorPictureEnabled:false, if 0 - blog-isAuthorPictureEnabled:true  */
const showLegacyAuthorInfoType = createStylesParam('blog-authorInfoType', {
  type: StyleParamType.Number,
  getDefaultValue: () => AUTHOR_INFO_TYPE_NAME_PICTURE,
});

/** @deprecated Legacy, if 1 - blog-isAuthorPictureEnabled:false, if 0 - blog-isAuthorPictureEnabled:true  */
const showLegacyAuthorInfoTypeMobile = createStylesParam('blog-mobile-authorInfoType', {
  type: StyleParamType.Number,
  getDefaultValue: () => AUTHOR_INFO_TYPE_NAME_PICTURE,
});

/** Prevents mobile parameters being saved as `"param▶︎m"` when changing values on Mobile breakpoint */
const dangerousKeyTransformationOverride = (key: string) => key;

const STYLE_PARAMS = {
  // #region Settings - Desktop
  showFeaturedPostsOnly: createStylesParam<StyleParamType.Boolean>('postListWidgetIsFeatured', {
    type: StyleParamType.Boolean,
    getDefaultValue: () => false,
  }),
  paginationType: createStylesParam<StyleParamType.Number>('postListWidgetPaginationType', {
    type: StyleParamType.Number,
    getDefaultValue: () => PostListWidgetPaginationType.NumberOfPosts,
  }),
  /** Range: 1-50. Enabled only when `paginationType` is `PostListWidgetPaginationType.NumberOfPosts` */
  postCount: createStylesParam<StyleParamType.Number>('postListWidgetEntityCount', {
    type: StyleParamType.Number,
    getDefaultValue: () => 3,
  }),
  /** Range: 1-50. Enabled only when `paginationType` is `PostListWidgetPaginationType.Paginated`  */
  postsPerPage: createStylesParam<StyleParamType.Number>('postListWidgetPostsPerPage', {
    type: StyleParamType.Number,
    getDefaultValue: () => 3,
  }),
  // #region Display - Desktop
  showAuthorName: createStylesParam<StyleParamType.Boolean>('blog-isAuthorNameEnabled', {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  }),
  showAuthorPicture: createStylesParam<StyleParamType.Boolean>('blog-isAuthorPictureEnabled', {
    type: StyleParamType.Boolean,
    getDefaultValue: ({ getStyleParamValue }) => {
      return getStyleParamValue(showLegacyAuthorInfoType) === AUTHOR_INFO_TYPE_NAME_PICTURE;
    },
  }),
  showPostPublishDate: createStylesParam<StyleParamType.Boolean>('blog-isPostPublishDateEnabled', {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  }),
  showReadingTime: createStylesParam<StyleParamType.Boolean>('blog-isReadingTimeEnabled', {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  }),
  showCoverImage: createStylesParam<StyleParamType.Boolean>('blog-isCoverImageEnabled', {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  }),
  showCategoryLabel: createStylesParam<StyleParamType.Boolean>('blog-isCategoryLabelEnabled', {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  }),
  showDescription: createStylesParam<StyleParamType.Boolean>('blog-isPostDescriptionEnabled', {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  }),
  showPostRating: createStylesParam<StyleParamType.Boolean>('blog-isPostRatingEnabled', {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  }),
  showCommentCount: createStylesParam<StyleParamType.Boolean>('blog-isCommentCountEnabled', {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  }),
  showViewCount: createStylesParam<StyleParamType.Boolean>('blog-isViewCountEnabled', {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  }),
  showLikeCount: createStylesParam<StyleParamType.Boolean>('blog-isLikeCountEnabled', {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  }),
  // #region Display - Mobile
  isMobileDisplaySettingsEnabled: createStylesParam<StyleParamType.Boolean>(
    'blog-mobile-isMobileDisplaySettingsEnabled',
    {
      type: StyleParamType.Boolean,
      dangerousKeyTransformationOverride,
      getDefaultValue: () => false,
    },
  ),
  showAuthorNameMobile: createStylesParam<StyleParamType.Boolean>(
    'blog-mobile-isAuthorNameEnabled',
    {
      type: StyleParamType.Boolean,
      dangerousKeyTransformationOverride,
      getDefaultValue: () => true,
    },
  ),

  showAuthorPictureMobile: createStylesParam<StyleParamType.Boolean>(
    'blog-mobile-isAuthorPictureEnabled',
    {
      type: StyleParamType.Boolean,
      dangerousKeyTransformationOverride,
      getDefaultValue: ({ getStyleParamValue }) => {
        return getStyleParamValue(showLegacyAuthorInfoTypeMobile) === AUTHOR_INFO_TYPE_NAME_PICTURE;
      },
    },
  ),
  showPostPublishDateMobile: createStylesParam<StyleParamType.Boolean>(
    'blog-mobile-isPostPublishDateEnabled',
    {
      type: StyleParamType.Boolean,
      dangerousKeyTransformationOverride,
      getDefaultValue: () => true,
    },
  ),

  showReadingTimeMobile: createStylesParam<StyleParamType.Boolean>(
    'blog-mobile-isReadingTimeEnabled',
    {
      type: StyleParamType.Boolean,
      dangerousKeyTransformationOverride,
      getDefaultValue: () => true,
    },
  ),

  showCoverImageMobile: createStylesParam<StyleParamType.Boolean>(
    'blog-mobile-isCoverImageEnabled',
    {
      type: StyleParamType.Boolean,
      dangerousKeyTransformationOverride,
      getDefaultValue: () => true,
    },
  ),
  showCategoryLabelMobile: createStylesParam<StyleParamType.Boolean>(
    'blog-mobile-isCategoryLabelEnabled',
    {
      type: StyleParamType.Boolean,
      dangerousKeyTransformationOverride,
      getDefaultValue: () => true,
    },
  ),
  showDescriptionMobile: createStylesParam<StyleParamType.Boolean>(
    'blog-mobile-isPostDescriptionEnabled',
    {
      type: StyleParamType.Boolean,
      dangerousKeyTransformationOverride,
      getDefaultValue: () => true,
    },
  ),
  showPostRatingMobile: createStylesParam<StyleParamType.Boolean>(
    'blog-mobile-isPostRatingEnabled',
    {
      type: StyleParamType.Boolean,
      dangerousKeyTransformationOverride,
      getDefaultValue: () => true,
    },
  ),
  showCommentCountMobile: createStylesParam<StyleParamType.Boolean>(
    'blog-mobile-isCommentCountEnabled',
    {
      type: StyleParamType.Boolean,
      dangerousKeyTransformationOverride,
      getDefaultValue: () => true,
    },
  ),
  showViewCountMobile: createStylesParam<StyleParamType.Boolean>('blog-mobile-isViewCountEnabled', {
    type: StyleParamType.Boolean,
    dangerousKeyTransformationOverride,
    getDefaultValue: () => true,
  }),
  showLikeCountMobile: createStylesParam<StyleParamType.Boolean>('blog-mobile-isLikeCountEnabled', {
    type: StyleParamType.Boolean,
    dangerousKeyTransformationOverride,
    getDefaultValue: () => true,
  }),
  // #endregion
  // #region Layout - Desktop
  layoutType: createStylesParam<StyleParamType.Number>('layout-post-list-layoutType', {
    type: StyleParamType.Number,
    getDefaultValue: () => Layout.PgGrid,
  }),
  // #endregion
  // #region Layout - Mobile
  isMobileLayoutSettingsEnabled: createStylesParam<StyleParamType.Boolean>(
    'blog-mobile-isMobileLayoutSettingsEnabled',
    {
      type: StyleParamType.Boolean,
      dangerousKeyTransformationOverride,
      getDefaultValue: () => false,
    },
  ),
  layoutTypeMobile: createStylesParam<StyleParamType.Number>('layout-mobile-post-list-layoutType', {
    type: StyleParamType.Number,
    dangerousKeyTransformationOverride,
    getDefaultValue: () => Layout.PgGrid,
  }),
  layoutSliderTitleFontSizeMobile: createStylesParam<StyleParamType.Number>(
    'post-slider-post-list-mobile-titleFontSize',
    { type: StyleParamType.Number, dangerousKeyTransformationOverride, getDefaultValue: () => 22 },
  ),
  layoutMagazineTitleFontSizeMobile: createStylesParam<StyleParamType.Number>(
    'post-pg-text-on-image-medium-post-list-mobile-titleFontSize',
    { type: StyleParamType.Number, dangerousKeyTransformationOverride, getDefaultValue: () => 22 },
  ),
  layoutSideBySideTitleFontSizeMobile: createStylesParam<StyleParamType.Number>(
    'post-list-post-list-mobile-titleFontSize',
    { type: StyleParamType.Number, dangerousKeyTransformationOverride, getDefaultValue: () => 14 },
  ),
  layoutOneColumnTitleFontSizeMobile: createStylesParam<StyleParamType.Number>(
    'post-pg-grid-post-list-mobile-titleFontSize',
    { type: StyleParamType.Number, dangerousKeyTransformationOverride, getDefaultValue: () => 22 },
  ),
  layoutOneColumnDescriptionFontSizeMobile: createStylesParam<StyleParamType.Number>(
    'post-pg-grid-post-list-mobile-descriptionFontSize',
    { type: StyleParamType.Number, dangerousKeyTransformationOverride, getDefaultValue: () => 16 },
  ),
  // #endregion
  // #region Design - Desktop
  // TODO
  // #endregion
  // #region Design - Mobile
  isMobileDesignSettingsEnabled: createStylesParam<StyleParamType.Boolean>(
    'blog-mobile-isMobileDesignSettingsEnabled',
    {
      type: StyleParamType.Boolean,
      dangerousKeyTransformationOverride,
      getDefaultValue: () => false,
    },
  ),
  // #endregion
} as const satisfies {
  [key: string]: Omit<IStyleParam, 'name'>;
};

type IStylesParams = GetIStylesParams<typeof STYLE_PARAMS>;

export const customCssVars: CustomCssVarsFn<StyleParamsValues<IStylesParams>> = () => {
  return {};
};

export default createStylesParams<IStylesParams>(STYLE_PARAMS);
